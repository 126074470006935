<template>
  <section>
    <div v-if="route.meta.HideHeader && route.meta.HideFooter">
      <page-custom :id="route.meta.ID as GUID" />
    </div>
    <div
      v-else
      class="v-container"
    >
      <page-custom :id="route.meta.ID as GUID" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GUID } from '@arora/common'

const route = useRoute()
</script>
